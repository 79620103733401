<template>
    <div class="row">
        <div class="col-sm-12 col-md-7">
            <div class="paging_simple_numbers">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" id="example2_previous">
                        <a @click="moveToFirst()" class="page-link"><i class="fas fa-angle-double-left"></i></a>
                    </li>
                    <li class="paginate_button page-item previous" id="example2_previous">
                        <a @click="moveToPage(prePages)" class="page-link"><i class="fas fa-angle-left"></i></a>
                    </li>
                    <li v-for="page in pages" :key="page" class="paginate_button page-item" :class="{ 'active': page === CurrentPage }">
                        <a @click="moveToPage(page)" class="page-link">{{ page }}</a>
                    </li>
                    <li class="paginate_button page-item next" id="example2_next">
                        <a @click="moveToPage(nextPages)" class="page-link"><i class="fas fa-angle-right"></i></a>
                    </li>
                    <li class="paginate_button page-item previous" id="example2_previous">
                        <a @click="moveToLast()" class="page-link"><i class="fas fa-angle-double-right"></i></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-sm-12 col-md-5 justify-content-end">
            <div class="dataTables_info pull-right">符合筆數:{{ filterTotal }} </div>
            <div class="dataTables_info pull-right">總筆數:{{ total }} </div>
            <div class="dataTables_length pull-right">
                <label>
                    每頁顯示
                    <select v-model="localPageSize" @change="changePageSize">
                        <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
                    </select>
                    筆
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        total: {
            required: true,
            type: Number,
            default: 0
        },
        filterTotal: {
            required: true,
            type: Number,
            default: 0
        },
        pageSize: {
            type: Number,
            default: 50
        },
        CurrentPage: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            pages: [],
            beginPage: 1,
            prePages: 1,
            nextPages: 1,
            pageSizes: [10, 20, 50, 100], // 新增可選的 pageSize
            localPageSize: this.pageSize // 使用本地數據來追蹤選取的 pageSize
        }
    },
    watch: {
        CurrentPage: 'updatePages',
        filterTotal: 'updatePages',
        pageSize(newVal) {
            this.localPageSize = newVal; // 當 pageSize prop 改變時，同步更新本地數據
            this.updatePages();
        }
    },
    methods: {
        updatePages() {
            const totalPages = Math.ceil(this.filterTotal / this.localPageSize);
            this.beginPage = Math.max(1, this.CurrentPage - 4);
            const endPage = Math.min(totalPages, this.beginPage + 9);

            this.pages = [];
            for (let i = this.beginPage; i <= endPage; i++) {
                this.pages.push(i);
            }

            this.prePages = Math.max(1, this.CurrentPage - 1);
            this.nextPages = Math.min(totalPages, this.CurrentPage + 1);
        },
        /** 每頁顯示筆數變更事件 */
        changePageSize(event) {
            this.localPageSize = parseInt(event.target.value); 
            this.$emit('PageSizeCallback', this.localPageSize);
        },
        moveToPage(pageNum) {
            const pNum = parseInt(pageNum);
            if (pNum < 1 || pNum > Math.ceil(this.filterTotal / this.pageSize)) {
                console.log('頁碼輸入異常！');
                return;
            }
            this.$emit('changeCallback', pNum);
        },
        moveToFirst() {
            this.$emit('changeCallback', 1);
        },
        moveToLast() {
            this.$emit('changeCallback', Math.ceil(this.filterTotal / this.pageSize));
        }
    },
    mounted() {
        this.updatePages();
    }
}
</script>
<style lang="css" scoped>
.pagination .page-item {
    height: 40px; /* 設定固定高度 */
    display: flex;
    align-items: center; /* 垂直置中 */
}

.dataTables_info {
    margin-left: 10px; /* 增加間距 */
}
</style>
