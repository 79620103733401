<script>
import Swal from 'sweetalert2'
import LayoutDiv from '@/components/LayoutDiv.vue'
import Pagination from '@/components/pagination.vue'
import HeaderIcon from '@/components/headerIcon.vue'

export default {
    name: 'baseList',
    components: {
        LayoutDiv,
        Pagination,
        HeaderIcon
    },
    data() {
        return {
            headers: []
        }
    },
    created() 
    {
        this.fetchList();
    },
    methods: {
        deleteRecord(id) {
            console.log(id);
        },
        handleDelete(id) {
            Swal.fire({
                title: '確定要刪除這筆資料?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '確定',
                cancelButtonText: '取消'
            }).
            then((result) => {
                if (result.isConfirmed) {

                    this.deleteRecord(id)
                    .then(response => {

                        this.fetchList();
                        return response
                    })                        
                }
            })
        },
        sort: function (s) {

            if (s == this.pagination.sortItem) {
                
                if( this.pagination.sortDir == 'ASC' )
                {
                    this.pagination.sortDir = 'DESC';
                    this.sorttIcon(s, "▲");
                }
                else
                {
                    this.pagination.sortDir = 'ASC';
                    this.sorttIcon(s, "▼");
                }

            }
            else {
                this.changeSortField(this.pagination.sortItem, s);
                this.pagination.sortItem = s;
                this.pagination.sortDir = 'ASC';
                this.sorttIcon(s, "▼");           
            }


            this.fetchList();
        },
        sorttIcon(id, icon)
        {
          
            this.headers.forEach((item, index) => {
                
                if(item.id === id)
                {
                    this.headers[index].icon = icon;
                    return true;
                }
            });

            return false;
        }, 
        changeSortField(oldfield, newfield)
        {
            this.headers.forEach((item, index) => {
                
                if(item.id === oldfield)
                {
                    this.headers[index].icon = "";
                }

                if(item.id === newfield)
                {
                    this.headers[index].icon = "▼";                    
                }
            });
        },
        onPageChange: function (page) {
            
            this.pagination.page = page;
            this.fetchList();
        },
        onPageSizeChange(newPageSize) {
			this.pagination.pageSize = newPageSize;
			this.pagination.page = 1; // 重置頁碼為第一頁
			this.fetchList();
		} 
    }
}
</script>